export const segments = [
  { text: "Mohammed Alhadhari" },
  { text: "Ruya Alsultan" },
  { text: "Musaab Alodail" },
  { text: "Yaser Albather" },
  { text: "Abdulaziz Almoabadi" },
  { text: "Bushra Alsayed" },
  { text: "Albatoul Alhaddad" },
  { text: "Mohammed Jawman" },
  { text: "Nardeen Alhazmi" },
  { text: "Yara Alwsaidi" },
  { text: "Abdulmalek Almuhareb" },
  { text: "Abdulmalik Aljasir" },
  { text: "Alanoud Alhindi" },
  { text: "Faisal Alzamil" },
  { text: "Fay Alotaibi" },
  { text: "Mohammed Bin Buraykan" },
  { text: "Haneen Alotaibi" },
  { text: "Ghaida Abdullah" },
  { text: "Shahd AlTuwijri" },
  { text: "Dhiyaa Alkaki" },
  { text: "Rakan Almutairi" },
  { text: "Yara Nawab" },
];
export const questions1 = [
  // Question 1
  {
    question:
      "According to DECLARE study Dapagliflozin reduced MACE Outcomes in Patients with Prior MI by ……….. RRR.",
    answers: ["16%", "17%", "47%", "24%"],
  },
  // Question 2
  {
    question:
      "According to DECLARE study Dapagliflozin showed regression in Albuminuria by ……..",
    answers: ["54%", "21%", "46%", "27%"],
  },
  // Question 3
  {
    question: "The 1ry superiority endpoint with DECLARE study was MACE only.",
    answers: ["True", "False"],
  },
  // Question 4
  {
    question:
      "The patients in DECLARE trial had worse baseline renal function than the EMPA-REG or CANAVAS trials.",
    answers: ["True", "False"],
  },
  // Question 5
  {
    question:
      "Total No. of the patients included in DECLARE is .......................",
    answers: ["17360", "17260", "17760", "17160"],
  },
  // Question 6
  {
    question: "Renal Specific endpoint in DECLARE study was reduced by:",
    answers: ["27%", "24%", "17%", "47%"],
  },
  // Question 7
  {
    question: "In DECLARE Prior HF Subgroup Analysis HHF RRR Outcome was:",
    answers: ["32%", "35%", "27%", "38%"],
  },
  // Question 8
  {
    question:
      "According to DECLARE study the mean eGFR (ml/min/1.73 m²) across the enrolled patients is:",
    answers: [
      "74.1 ml/min/1.73 m²",
      "85.2 ml/min/1.73 m²",
      "80.2 ml/min/1.73 m²",
      "76.5 ml/min/1.73 m²",
    ],
  },
  // Question 9
  {
    question:
      "According to DECLARE outcomes Dapagliflozin patients had a significant relative risk reduction of hHF events by ……… compared to placebo.",
    answers: ["27%", "16%", "17%", "14%"],
  },
  // Question 10
  {
    question: "In DECLARE study: Renal composite endpoint was reduced by",
    answers: ["47%", "24%", "27%", "17%"],
  },
  // Question 11
  {
    question:
      "According to DECLARE outcomes Dapagliflozin patients had a significant relative risk reduction of hHF/CV death events by ……… compared to placebo.",
    answers: ["16%", "14%", "27%", "17%"],
  },
  // Question 12
  {
    question: "DECLARE is the only CVOT that has ………",
    answers: [
      "MACE as a primary endpoint",
      "HHF as a primary endpoint",
      "Been published before product market approval",
      "Included primary and secondary prevention populations",
    ],
  },
  // Question 13
  {
    question:
      "According to Declare trial for patient with HFrEF Dapagliflozin reducing CV death by...........RRR and it was seen early and continued throughout the trial.",
    answers: ["18%", "38%", "16%", "45%"],
  },
  // Question 14
  {
    question:
      "Dapagliflozin significantly reduced the risk of CV death or worsening HF in patients with LVEF >40 % as early as ........",
    answers: ["Day 28", "Day 15", "Day 13", "Day 14"],
  },
  // Question 15
  {
    question:
      "In EMPEROR-Reduced Trial the CV death was significantly reduced by 17% RRR.",
    answers: ["False", "True"],
  },
  // Question 16
  {
    question:
      "KCCQ-TSS was improved by ....... points in Deliver trial while it was improved by .......points in DAPA-HF trials in Dapagliflozin arms.",
    answers: ["2.4 & 2.5", "2.8 & 2.4", "2.4 & 2.8", "2.4 & 2.3"],
  },
  // Question 17
  {
    question:
      "Dapagliflozin significantly reduced the risk of worsening HF in patients with HFrEF by …… RRR",
    answers: ["25%", "29%", "26%", "30%"],
  },
  // Question 18
  {
    question:
      "In DAPA-HF trial : The Proportion of patients with an existing diagnosis of type II diabetes was ……… while the proportion of patients without diabetes was.......",
    answers: ["45%-55%", "48%-52%", "55%-45%", "58%-42%"],
  },
  // Question 19
  {
    question:
      "Forxiga’s principal mechanism of HF management is uniquely through the diuretic effect on interstitial volume reduction rather than intravascular volume reduction",
    answers: ["False", "True"],
  },
  // Question 20
  {
    question:
      "Heart Failure with preserved ejection fraction is known as systolic heart failure where the heart struggles to pump",
    answers: ["False", "True"],
  },
  // Question 21
  {
    question:
      "According to Dapa-HF and DELIVER pooled analysis the MACE endpoint was reduced by…………. RRR:",
    answers: ["29%", "17%", "10%", "14%"],
  },
  // Question 22
  {
    question:
      "ECG is the standard test for characterizing structural and functional changes in HF",
    answers: ["False", "True"],
  },
  // Additional questions continued in the same format
];

export const questions2 = [
  // Question 23
  {
    question:
      "In DAPA-HF trial RRR in primary endpoints composite of (CV death/worsening of HF) was………",
    answers: ["26%", "29%", "30%", "45%"],
  },
  // Question 24
  {
    question:
      "In Emperor-Reduced trial 1ry End Point; was reduced by …………. RRR in patients randomized to Empa versus placebo",
    answers: ["30%", "18%", "26%", "25%"],
  },
  // Question 25
  {
    question: "The number of patients enrolled in DAPA HF trial was………",
    answers: ["4170", "17170", "4744", "4544"],
  },
  // Question 26
  {
    question:
      "In DAPA-HF trial the new onset of diabetes in HFrEF patients was significantly reduced by ..…… for Dapa treated patients vs placebo.",
    answers: ["38%", "30%", "32%", "33%"],
  },
  // Question 27
  {
    question:
      "Dapagliflozin significantly reduced the risk of CV death or worsening HF in patients with LVEF <40 % as early as ........",
    answers: ["Day 14", "Day 13", "Day 28", "Day 15"],
  },
  // Question 28
  {
    question:
      "In DAPA-HF trial RRR of CV death was …… and the RRR for All Cause Death was …… for Dapa treated patients",
    answers: ["30% & 26%", "18% & 17%", "17% & 18%", "20% & 17%"],
  },
  // Question 29
  {
    question:
      "Which HF guidelines mentioned Dapagliflozin by name for its mortality benefit?",
    answers: ["AHA/ACC/HFSA", "ESC", "All of the above", "None of the above"],
  },
  // Question 30
  {
    question:
      "Patients with moderately increased UACR level range from 30-300 considered as Albuminuria Category ..... For diagnosis of CKD",
    answers: ["A1", "A2", "A4", "A3"],
  },
  // Question 31
  {
    question: "UACR range for patients included in DAPA CKD is ……..mg/g",
    answers: ["≥30 to ≤300", "≥200 to ≤1000", "≥200 to ≤5000", "≥500 to ≤5000"],
  },
  // Question 32
  {
    question:
      "FORXIGA offers a complementary pathway to RAAS inhibition to lower intraglomerular pressure and protect nephrons through:",
    answers: [
      "Afferent arteriole vasodilation",
      "Efferent arteriole vasoconstriction",
      "Afferent arteriole vasoconstriction",
      "Efferent arteriole vasodilation",
    ],
  },
  // Question 33
  {
    question:
      "Which of the following is not considered as exclusion criteria in DAPA CKD population",
    answers: [
      "Non-Diabetic patients",
      "T1D",
      "Polycystic Kidney disease",
      "lupus nephritis",
    ],
  },
  // Question 34
  {
    question:
      "According to 2023 CKD KDIGO Guidelines SGLT2i’s have class ...... for treating adults with CKD and heart failure or eGFR ≥20 mL/min/1.73 m2 with UACR ≥200 mg/g",
    answers: ["1(B)", "2(B)", "2(A)", "1(A)"],
  },
  // Question 35
  {
    question:
      "RRR IN DAPA CKD trail for Chronic Dialysis Kidney Transplantation or Renal Death was......",
    answers: ["32%", "34%", "33%", "35%"],
  },
  // Question 36
  {
    question: "RRR in the primary endpoint in Empakidney trial is .....",
    answers: [
      "28% non-significant RRR",
      "38% Significant RRR",
      "38% non-significant RRR",
      "28% Significant RRR",
    ],
  },
  // Question 37
  {
    question: "In EMPA KIDNEY trail RRR in Death from any cause was .....",
    answers: [
      "non-significant 15% RRR",
      "Significant 15% RRR",
      "significant 13% RRR",
      "non-significant 13% RRR",
    ],
  },
  // Question 38
  {
    question:
      "According to DAPA-CKD trial Dapagliflozin significantly reduced the 1ry end point by …… RRR for patients with IgA nephropathy",
    answers: ["71%", "39%", "31%", "18%"],
  },
  // Question 39
  {
    question: "Median Follow up in DAPA CKD Was ...years",
    answers: ["2", "2.4", "3", "2.2"],
  },
  // Question 40
  {
    question:
      "In DAPA CKD trial RRR for Primary composite endpoint for non-Diabetic patients was:",
    answers: ["36% RRR", "50% RRR", "31% RRR", "39% RRR"],
  },
  // Question 41
  {
    question: ".......% of non-Diabetic patients enrolled in DAPA CKD",
    answers: ["22%", "32%", "68%", "52%"],
  },
  // Question 42
  {
    question: "The number of patients enrolled in DAPA CKD trial was……",
    answers: ["4304", "4447", "17170", "4403"],
  },
  // Question 43
  {
    question: "Dapagliflozin showed ……… RRR in All-Cause Death in DAPA-CKD",
    answers: ["33%", "31%", "18%", "17%"],
  },
  // Question 44
  {
    question:
      "Dapagliflozin showed ……… RRR in composite of HHF/CV Death in DAPA-CKD",
    answers: ["26%", "29%", "30%", "12%"],
  },
  // Additional questions followed in the same pattern...
];
