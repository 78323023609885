
import React, { useEffect, useRef } from 'react';
import Winwheel from '../WinWheelScript';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import { questions1, segments } from '../constants';

const WheelOfFortune = ({ setShowResult }) => {

    const [localSegments, setLocalSegments, clearAllowedSegments] = useLocalStorageState('segments', segments.map((segment, i) => ({ ...segment, probability: 1, index: i })));
    function preLoadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img); // Resolve the promise with the loaded image
            img.onerror = (error) => reject(error); // Reject the promise on error
        });
    }



    const canvasRef = useRef(null);
    const wheelRef = useRef(null);
    function playSound() {
        const audio = new Audio('tick.mp3')
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    }
    const alertPrize = (indicatedSegment) => {
        // change probability of the local segment to 0
        let newSegments = [...localSegments];
        newSegments[indicatedSegment.index].probability = 0; // 0 means the segment is not allowed to be selected again
        setLocalSegments(newSegments);
        setShowResult({
            name: indicatedSegment.text,
            question: indicatedSegment.question,
        });
    };
    const startWheel = () => {
        let winningSegmentIndex = calculateSpinResult(); // Index of the segment to win
        if (winningSegmentIndex === null) {
            // alert ok and cancel to start again and clear the segments

            const result = window.confirm('No segments to spin, do you want to clear the segments and start again?');
            if (result) {
                clearAllowedSegments();
                window.location.reload();
            }

            return;
        }
        console.log({ winningSegmentIndex });
        wheelRef.current.animation.stopAngle = wheelRef.current.getRandomForSegment(winningSegmentIndex + 1); // Set stop angle to the index of the segment to win
        if (wheelRef.current) {
            wheelRef.current.startAnimation();
        }
    };
    function calculateSpinResult() {
        // let allowedSegmentsIndexes = [3, 4]; // Indexes of allowed segments
        // get indexes of segments
        // let winningSegmentIndex = null;

        // get all segments with probability > 0 from localSegments and update the indexes of the segments


        let allowedSegmentsIndexes = localSegments.filter(segment => segment.probability > 0).map(segment => segment.index);

        if (allowedSegmentsIndexes.length === 0) {
            return null;
        }
        let winningSegmentIndex = allowedSegmentsIndexes[Math.floor(Math.random() * allowedSegmentsIndexes.length)];
        return winningSegmentIndex;





    }
    useEffect(() => {
        preLoadImage('./segment3.png').then(() => {
            wheelRef.current = new Winwheel({
                canvasId: "wheelCanvas",
                numSegments: localSegments.length,
                'responsive': true,  // This wheel is responsive!
                segments: localSegments.map(segment => ({
                    // 'fillStyle': segment.fillStyle,
                    'fillStyle': 'white',
                    // 'strokeStyle': 'red',
                    'textFillStyle': segment.probability <= 0 ? 'grey' : 'rgba(245, 90, 90, 1)',
                    'textFontSize': 46,
                    'strokeWidth': 0,
                    'probability': segment?.probability || 0, // Set probability of winning.
                    'text': segment.text, // You can use text for labels or leave it empty if using images
                    'index': segment.index,
                    'question': questions1[segment.index],
                    'image': segment.probability <= 0 ? './segment3.png' : './segment3.png', // Optional, if you want to use images
                })),
                'outerRadius': 1200,               // Set outer radius so wheel fits inside the background.
                'innerRadius': 190,               // Make wheel hollow so segments don't go all way to center.
                'drawText': true,              // Code drawn text can be used with segment images.
                // 'textOrientation': 'curved',
                'textAlignment': 'inner',
                'textFontFamily': 'arial',
                // 'textStrokeStyle': 'black',
                'textLineWidth': 3,
                // 'textFillStyle': 'red',
                'drawMode': 'segmentImage',    // Must be segmentImage to draw wheel using one image per segemnt.
                'animation': {
                    'type': "spinToStop",
                    'duration': 6,
                    'spins': 4,
                    'callbackFinished': alertPrize,
                    'callbackSound': playSound,
                    'soundTrigger': "segment",
                },
                'pins':
                {
                    'responsive': true, // This must be set to true if pin size is to be responsive.
                },
            });
         
        });
       
    }, []);


    return <div id='wheel_container'>

        <button
            id='spin_button'
            onClick={startWheel}>
        </button>

        <button id='reset_segments' onClick={() => {
            const result = window.confirm('Do you want to clear the segments and start again?');
            if (result) {
                clearAllowedSegments();
                window.location.reload();
            }
        }
        }>
            <img src={"./reset.png"} alt={"reset image"}/>
        </button>
        <div>
            <canvas id="wheelCanvas" ref={canvasRef}
                width="5100" height="1900"
                data-responsiveMinWidth="180"
                data-responsiveScaleHeight="true"
                data-responsiveMargin="50"
            >
            </canvas>
            <img src="./indicator.png" alt="wheel" id='indicator' />
        </div>
    </div>
        ;
};

export default WheelOfFortune;
