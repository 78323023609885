import { useState, useEffect } from "react";

export function useLocalStorageState(key, defaultValue) {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  // Function to clear the state from localStorage and reset it to default value
  const clearState = () => {
    localStorage.removeItem(key); // Remove the item from localStorage
    setState(defaultValue); // Reset state to default value
  };

  // Return state, setState, and clearState from the hook
  return [state, setState, clearState];
}
